import { Map, fromJS, get } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { simpleGetInObject } from '@dbh/lodash-extra';
import { LOADING_STATUS } from '@dbh/generic-redux-constants';
import { USER_BOOKINGS_LOAD_SUCCESS, USER_BOOKINGS_LOADING, USER_BOOKINGS_LOAD_FAILED } from '@dbh/user-area-bookings-redux';
import { SUPPORTED_SIGN_IN_PROVIDERS, USER_LOAD_STATES, SOCIAL_SIGN_IN_REQUEST_INVOKED, SOCIAL_SIGN_IN_REQUEST_SUCCESS, SOCIAL_SIGN_IN_REQUEST_FAILED, REDUX_AND_SAGA_KEY, USER_LOGIN_STATUS_CHANGED, USER_GEOLOCATION_POSITION_RETRIEVED, SOCIAL_SIGN_IN_PROVIDER_SETUP_ERROR, SOCIAL_SIGN_IN_PROVIDER_SETUP_SUCCESS, SOCIAL_SIGN_SETUP_INVOKED, makeReducerKeyPathForUserBookings, makeReducerKeyPathForUserBookingsLoaded } from './index.js';
export { REDUX_AND_SAGA_KEY } from './index.js';
import 'jwt-decode';
import '@dbh/invariant-errors-and-warnings';
import '@dbh/reselect-extra';
import 'prop-types';
import '@dbh/admindev-constants';
import '@dbh/with-conforms-to-for-production-www';
import '@dbh/environment';
import 'react-immutable-proptypes';
import '@dbh/generic-types';
import '@dbh/redux-extra';
import 'lodash/noop';
import '@dbh/loyalty-types';
import 'invariant';
import '@dbh/with-conforms-to-extra';
import 'email-validator';
import '@dbh/cookies';
import '@dbh/cookie-keys';
import 'redux-saga/effects';
import '@dbh/redux-saga-extra';
import '@dbh/throw-in-server-side-rendering';
import '@dbh/validation';
import 'reselect';
import '@babel/runtime/helpers/esm/jsx';
import 'react';
import '@dbh/content-placeholder';
import '@dbh/loading-placeholder';
import 'react/jsx-runtime';
import 'universal-cookie';

const {GOOGLE_SIGN_IN_PROVIDER_NAME,FACEBOOK_SIGN_IN_PROVIDER_NAME}=SUPPORTED_SIGN_IN_PROVIDERS,initialUserState=Map({user:void 0,userPermissions:Map({isBusinessUser:void 0,isBusinessAdminUser:void 0}),idPartner:void 0,userBookings:Map()}),userAreaDataReducer=function(a,b){void 0===a&&(a=initialUserState);const{type:c,payload:d}=b;switch(c){case USER_LOGIN_STATUS_CHANGED:{const{user:b,error:c,status:e,isBusinessUser:f,isBusinessAdminUser:g,idPartner:h}=d;return c?initialUserState:e===USER_LOAD_STATES.NONE?initialUserState:e===USER_LOAD_STATES.LOADED?a.merge({user:b,userPermissions:Map({isBusinessUser:f,isBusinessAdminUser:g}),idPartner:h}):e===USER_LOAD_STATES.RESET_PASSWORD_REQUESTED?a:a}case USER_BOOKINGS_LOAD_SUCCESS:{const{apiResponse:b,country:c,locale:e,pageNumber:f}=d,{baseCdnImageUrl:g,"hydra:totalPages":h,"hydra:totalItems":i}=b,j=Map({// Note that `pageNumber` is intentionally typecasted to `string`.
// It avoids the issue of `Immutable.Map` having number as valid keys
// while plain JavaScript `objects` cast numbers passed as keys to string.
[f]:fromJS(b["hydra:member"])}),k=makeReducerKeyPathForUserBookings(c,e);// Note: we cannot cache user reservation data due to the likely chance
// that one of the reservation status or amount, changes in another tab or
// client. It is preferable to always call the `API` on page change, rather
// than risking to display to stale data when the user revists a cached
// page. @example The user goes from page one, to page two, than goes back
// to page one.
return a.updateIn(k,a=>{// These are the user bookings for the current country and locale (which
// we concatenate to form the `keyPath` in the reducer).
const b=get(a,"bookings",Map()),c=b.merge(j),d={totalItems:i,totalPages:h,bookings:c,baseCdnImageUrl:g};return a?a.merge(d):Map(d)})}default:return a}},initialUserInfoStatus=Map({loadState:USER_LOAD_STATES.NONE,tokens:Map({accessToken:void 0,refreshToken:void 0}),latestError:void 0,signInProvider:void 0,userBookingsLoadStatus:Map({})}),userAreaInfoReducer=function(a,b){void 0===a&&(a=initialUserInfoStatus);const{type:c,payload:d}=b;switch(c){case USER_LOGIN_STATUS_CHANGED:{const{status:b,error:c,tokens:e,signInProvider:f}=d;if(c){const{message:d}=c;return a.merge({loadState:b,id:void 0,tokens:void 0,latestError:d})}if(b===USER_LOAD_STATES.NONE)return initialUserInfoStatus;if([USER_LOAD_STATES.LOADING_AFTER_TOKENS_RETRIEVED,USER_LOAD_STATES.LOADED].includes(b)){const c={loadState:b};e&&(c.tokens=e);const d=a.get("signInProvider");// We add `signInProvider` from the event to the state, if it is not
// already there. We enforce that `signInProvider` can be set once per
// user session.
return f&&!d&&(c.signInProvider=f),a.merge(c)}return a}case USER_BOOKINGS_LOAD_SUCCESS:case USER_BOOKINGS_LOAD_FAILED:case USER_BOOKINGS_LOADING:{const{country:b,locale:e}=d,f={[USER_BOOKINGS_LOAD_SUCCESS]:LOADING_STATUS.LOADED,[USER_BOOKINGS_LOADING]:LOADING_STATUS.LOADING,[USER_BOOKINGS_LOAD_FAILED]:LOADING_STATUS.NONE},g=f[c],h=makeReducerKeyPathForUserBookingsLoaded(b,e);return a.setIn(h,g)}default:return a}},userLocationInitialState=Map({country:void 0,zipCode:void 0,city:void 0,coordinates:void 0,placeId:void 0,formattedAddress:void 0}),userLocationReducer=function(a,b){void 0===a&&(a=userLocationInitialState);const{type:c,payload:d}=b;switch(c){case USER_GEOLOCATION_POSITION_RETRIEVED:{const{// @see {@link https://api.daybreakhotels.com/v1.0/IT/it-IT/geocodes?lat=41.8802102&lng=12.4689146}
apiResponse:{"hydra:member":a}}=d,b=a[0];// No results.
if(!b)return initialUserState;const c=b.formatted_address,e=b.place_id,f=b.address_components,g=simpleGetInObject(b,["geometry","location"]),{country:h,city:i,zipCode:j}=f.reduce((a,b)=>{let{types:c,short_name:d}=b;/* eslint-enable no-param-reassign */return c.includes("country")?a.country=d:c.includes("locality")?a.city=d:c.includes("postal_code")&&(a.zipCode=d),a},{});return Map({formattedAddress:c,coordinates:Map(g),placeId:e,country:h,city:i,zipCode:j})}default:return a}},socialSignInInitialState=Map({info:Map({hasInvokedSetup:!1,socialSignInLoadingStatus:!1}),[GOOGLE_SIGN_IN_PROVIDER_NAME]:Map({loadState:LOADING_STATUS.NONE,error:void 0}),[FACEBOOK_SIGN_IN_PROVIDER_NAME]:Map({loadState:LOADING_STATUS.NONE,error:void 0})}),MAP_EVENT_TYPE_TO_SOCIAL_SIGN_IN_STATUS=Object.freeze({[SOCIAL_SIGN_IN_REQUEST_INVOKED]:LOADING_STATUS.LOADING,[SOCIAL_SIGN_IN_REQUEST_SUCCESS]:LOADING_STATUS.LOADED,[SOCIAL_SIGN_IN_REQUEST_FAILED]:LOADING_STATUS.NONE}),socialSignInReducer=function(a,b){void 0===a&&(a=socialSignInInitialState);const{type:c,payload:d}=b;switch(c){case USER_LOGIN_STATUS_CHANGED:{const{status:b}=d,c=b===USER_LOAD_STATES.NONE;return c?a.setIn(["info","socialSignInLoadingStatus"],LOADING_STATUS.NONE):a}case SOCIAL_SIGN_IN_REQUEST_INVOKED:case SOCIAL_SIGN_IN_REQUEST_SUCCESS:case SOCIAL_SIGN_IN_REQUEST_FAILED:return a.setIn(["info","socialSignInLoadingStatus"],MAP_EVENT_TYPE_TO_SOCIAL_SIGN_IN_STATUS[c]);case SOCIAL_SIGN_SETUP_INVOKED:{const b=a.getIn(["info","hasInvokedSetup"]);// Social signIn providers are setup once in the browser.
// Subsequent dispatches of this event have no effect.
return b?a:a.withMutations(a=>{const b=Map({loadState:LOADING_STATUS.LOADING});a.setIn(["info","hasInvokedSetup"],!0).merge({[FACEBOOK_SIGN_IN_PROVIDER_NAME]:b});})}case SOCIAL_SIGN_IN_PROVIDER_SETUP_SUCCESS:{const{signInProvider:b}=d;return a.set(b,Map({loadState:LOADING_STATUS.LOADED}))}case SOCIAL_SIGN_IN_PROVIDER_SETUP_ERROR:{const{error:b,signInProvider:c}=d;return a.set(c,Map({loadState:LOADING_STATUS.NONE,error:b}))}default:return a}},userAreaReducer=combineReducers({// eslint-disable-next-line id-blacklist
data:userAreaDataReducer,info:userAreaInfoReducer,location:userLocationReducer,socialSignIn:socialSignInReducer});// This reducer contains the "raw" user data coming from the `API`.
// This reducer contains the "load state" of the user.
// Consider using a `redux` reducer factory taking the social provider code
// as a parameter (@example `GOOGLE_SIGN_IN_PROVIDER_NAME`) if more than two
// social sign in providers must be implemented.
// `redux-immutable - combineReducer` is like the `redux` version does not return
// a new state if all the reducers mapped to keys return the previous value.
// @see {@link https://github.com/gajus/redux-immutable/blob/master/src/combineReducers.js}
// @see {@link https://github.com/reduxjs/redux/blob/master/src/combineReducers.js#L176}
const injectedReducerConfiguration={key:REDUX_AND_SAGA_KEY};

export { userAreaReducer as default, injectedReducerConfiguration };
